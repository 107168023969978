.back-to-top,
#main-footer,
.cinema-select-container-mobile,
#main-header,
#main-header + .spacer {
  display: none;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  background: #fff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #3d3d3d;
}
